.nsf-section {
  min-height: 100vh;
  padding: 4rem;
  color: black;
  position: relative;
  overflow: hidden;
  padding-top: 12rem;
  z-index: 1;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.nsf-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 6rem;
  align-items: center;
}

.nsf-logo {
  width: 100%;
  max-width: 200px;
}

.nsf-main-title {
  font-size: 5.25rem;
  font-weight: bold;
  color: #000000;
  margin-bottom: 4rem;
}

.nsf-sub-title {
  font-size: 2.75rem;
  font-weight: bold;
  color: #000000;
  margin-bottom: 6rem;
}

.nsf-button {
  background-color: #3eb6fb;
  color: #ffffff;
  padding: 15px 30px;
  border: none;
  border-radius: 40px;
  font-size: 1.75rem;
  font-weight: 600;
}
